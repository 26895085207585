import React from 'react';
import Marquee from "react-fast-marquee";

function Parteners() {
   return (
      <section className="py-20">
         <div className="banner">
            <div className='text-center'>
               <h3 className="substitle py-1">Ils nous font confiance</h3>
               <div className="trait mx-auto"></div>
            </div>
            <div className="py-10">
                  <Marquee speed={40} className="-z-10">
                     <div className='p-3 mx-4'>
                        <img src="./images/Shell-gabon.jpg" alt="Logo Shell Gabon"  className='w-80'/>
                     </div>
                     <div className='p-3 mx-4'>
                        <img src="./images/Assala.jpg" alt="Logo Assala Gabon"  className='w-80'/>
                     </div>
                     <div className='p-3 mx-4'>
                        <img src="./images/Dokmee.jpg" alt="Logo Dockmee"  className='w-80'/>
                     </div>
                  </Marquee>
            </div>
         </div>
      </section>
   )
}

export default Parteners;
