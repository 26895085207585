import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
//CHANGEMENT DE COULEUR DE LA BARRE DU MENU DE NAVIGATION AU SCROLL
    const [color, setColor] = useState(false);
    const changeColor = () => {
        if(window.scrollY >= 100) {
            setColor(true);
        }else {
            setColor(false);
        }
    }

    window.addEventListener('scroll', changeColor);
    //BOUTON QUI AFFICHE LE MENU SUR MOBILE
    const [menu, setMenu] = useState(false);
    const handleMenu = () => setMenu(!menu);
    return (
        <Fragment>
            <header className={ color ? 'header headerScroll z-10' : 'header z-10' }>
                <nav className='container mx-auto px-7 py-2 md:flex md:items-center md:justify-between'>
                    <div className="flex justify-between items-center">
                        <a href='/' className='text-2xl font-bold uppercase tracking-wide'>
                            Group - GED
                        </a>
                        <button className='md:hidden block' onClick={handleMenu}>
                            {menu ? <i className='bx bx-x text-3xl'></i> : <i className='bx bx-menu-alt-left text-3xl'></i>}
                        </button>
                    </div>

                    <div className="hidden md:inline-flex space-x-4">
                        <NavLink to="/"> Accueil </NavLink>
                        <NavLink to="/a-propos"> A propos </NavLink>
                        <NavLink to="/filiales"> Nos filiales </NavLink>
                        <NavLink to="/blog"> Notre actualité </NavLink>
                    </div>
                </nav>

                {/* MENU MOBILE */}
                <div className={ menu ? 'mobilemenu ' : 'hidden mobilemenu' }>
                        <div className='flex flex-col space-y-6'>
                            <NavLink to="/"> Accueil </NavLink>
                            <NavLink to="/a-propos"> A propos </NavLink>
                            <NavLink to="/filiales"> Nos filiales </NavLink>
                            <NavLink to="/blog"> Notre actualité </NavLink>
                        </div>
                </div>
            </header>

        </Fragment>
    )
}


export default Navbar;
