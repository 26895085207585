import React, { Fragment} from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Teams from '../components/filiales/Teams';
import Entreprises from '../components/filiales/Entreprises';
import Parteners from '../components/home/Parteners';

function Filiales() {
   return (
      <Fragment>
         <Navbar />

         <main>
            <Teams />
            <Entreprises />
            <Parteners />
         </main>

         <Footer />
      </Fragment>
   )
}

export default Filiales;
