import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Error from '../images/Error.svg';

function Nofound() {
  return (
    
    <Fragment>
          <main>
              <section className='py-12'>
                  <div className="banner">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                      <div>
                        <img src={Error} alt="Représentation de l'erreur 404" className='w-full mx-auto' />
                      </div>

                      <div className='my-auto text-right'>
                        <h1 className="title underline">ERREUR !</h1>
                          <span className='py-5 substitle'>
                            Page non trouvée
                          </span>
                          <p className="para py-3">
                              La page que vous souhaitez consulter n'est pas ou plus disponible.
                              Cliquez sur le bouton pour revenir à la page d'accueil.
                          </p>
                          <div className='py-3'>
                          <Link to="/" className='btn'>Retour à l'accueil</Link>
                          </div>
                          
                      </div>
                    </div>
                      
                  </div>
              </section>
          </main>
          
          <Footer />
    </Fragment>
  )
}

export default Nofound;