import React from 'react';

function Aboutgroupged() {
   return (
      <section className='py-12' id='groupGed'>
         <div className="banner">
            <h3 className='substitle pb-1'>Le Group-GED</h3>
            <div className="trait"></div>
            <p className='py-5 para'>
               Le Group-GED est spécialisé dans gestion Des documents, des données de l’information et des services de
               proximité. Nos solutions garantissent à nos clients un meilleur niveau de performance. L’étendue de notre offre et
               la profondeur de notre expertise nous permettent d’accompagner les entreprises dans leurs projets de gestion
               documentaire et logistique.
               Notre savoir-faire et nos compétences reposent sur un bureau d'étude, une équipe jeune expérimentée et
               motivée.
            </p>
            <br />
            <span className='para pb-6'>Le GROUP-GED est composé de 4 filiales.</span>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 py-5 px-4">
               <div>
                  <a href="https://sggddi.group-ged.com" rel="noreferrer" target="_blank">
                     <img src="../../images/SGGDDI.png" alt="Le logo de SGGDDI" className='p-4 w-40 mx-auto' />
                  </a>
               </div>

               <div>
               <a href="https://ged-afric.group-ged.com" rel="noreferrer" target="_blank">
                  <img src="../../images/GED-Afric.png" alt="Le logo de GED Afric" className='w-48 mx-auto' />
               </a>
                  
               </div>

               <div>
                  <a href="https://ged-services.group-ged.com" rel="noreferrer" target="_blank">
                     <img src="../../images/GED-Services.png" alt="Le logo de GED Services" className='p-4 w-60 mx-auto' />
                  </a>
               </div>

               <div>
               <a href="https://ged-consulting.group-ged.com" rel="noreferrer" target="_blank">
                  <img src="../../images/GED-Consulting.png" alt="Le logo de GED Consulting" className='p-6 w-40 mx-auto' />
               </a>
               </div>
            </div>
         </div>
      </section>
   )
}

export default Aboutgroupged;
