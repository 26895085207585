
function Teams() {
   return (
      <section className="w-full bg-filiales-img h-[720px] bg-cover bg-center bg-no-repeat object-cover">
         <div className="banner px-7 py-24 md:px-10">
            <div className='py-24 bg-black/40 px-5 rounded-lg text-center'>
               <h1 className='text-white font-ged font-black text-4xl md:text-6xl'>Découvrez nos filiales</h1>
            </div>
         </div>
      </section>
   )
}
export default Teams;