import React from 'react';

function Entreprises() {
   return (
      <section className='py-12 bg-gray-100'>
         <div className="banner">
            <h3 className='substitle pb-1'>Nos filiales </h3>
            <div className="trait"></div>
            <p className='px-4 py-7 para text-center'>
               Faites l'expérience de nos filiales et découvrez l'ensemble de nos activités.
            </p>
            {/* LE BLOC CI-APRES INTRODUIT LES FILIALES ET LES ORIENTES LES VISITEURS VERS LA PAGE DEDIE */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 py-5">
               {/* FILIALE N°1 */}
               <div>
                  <details className='bg-white rounded-2xl overflow-hidden text-gray-700 shadow-xl shadow-[#fa5c52] cursor-pointer'>
                     <summary className='font-black text-xl bg-[#fa5c52] text-white p-3'>SGGDDI</summary>
                     <div className='bg-white pt-4 pb-6'>
                        <img src="../../images/SGGDDI.png" alt="Le logo de SGGDDI" className='py-1 w-32 mx-auto' />
                        <p className="para text-center py-2">
                           Nous travaillons dans la conservation de la mémoire de votre organisation en apportant notre
                           expérience et notre conseil en matière archivistique.
                        </p>

                        <a href="https://sggddi.group-ged.com/" rel="noreferrer" target="_blank"
                           className='bg-[#fa5c52] text-white p-2 rounded-2xl mx-[16%] w-full'>
                              Découvrir SGGDDI
                        </a>
                     </div>
                  </details>
               </div>

               {/* FILIALE N°2 */}
               <div>
                  <details className='bg-white rounded-2xl overflow-hidden text-gray-700 shadow-xl shadow-[#9FB366] cursor-pointer'>
                     <summary className='font-black text-xl bg-[#9FB366] text-white p-3'>GED AFRIC</summary>
                     <div className='bg-white pt-4 pb-6'>
                        <img src="../../images/GED-Afric.png" alt="Le logo de GED Afric" className='py-1 w-44 mx-auto' />
                        <p className="para text-center py-3">
                           GED Afric assure la gestion électronique de vos documents et données en toute sécurité et proche
                           de vous.
                        </p>

                        <a href="https://ged-afric.group-ged.com" rel="noreferrer" target="_blank"
                           className='bg-[#9FB366] text-white p-2 rounded-2xl mx-[16%] w-full'>
                              Découvrir GED Afric
                        </a>
                     </div>
                  </details>
               </div>

               {/* FILIALE N°3 */}
               <div>
                  <details className='bg-white rounded-2xl overflow-hidden text-gray-700 shadow-xl shadow-[#fdb053] cursor-pointer'>
                     <summary className='font-black text-xl bg-[#fdb053] text-white p-3'>GED SERVICES</summary>
                     <div className='bg-white pt-4 pb-6'>
                        <img src="../../images/GED-Services.png" alt="Le logo de GED Services" className='py-2 w-52 mx-auto' />
                        <p className="para text-center py-4">
                        Nous nous positionnons pour répondre de manière efficace au besoin du marché de la location de 
                        véhicules et logistique.
                        </p>

                        <a href="https://ged-services.group-ged.com" rel="noreferrer" target="_blank"
                           className='bg-[#fdb053] text-white p-2 rounded-2xl mx-[14%] w-full'>
                              Découvrir GED Services
                        </a>
                     </div>
                  </details>
               </div>

               {/* FILIALE N°3 */}
               <div>
                  <details className='bg-white rounded-2xl overflow-hidden text-gray-700 shadow-xl shadow-[#5f9fad] cursor-pointer'>
                     <summary className='font-black text-xl bg-[#5f9fad] text-white p-3'>GED Consulting</summary>
                     <div className='bg-white pt-4 pb-6'>
                        <img src="../../images/GED-Consulting.png" alt="Le logo de GED Services" className='p-2 w-32 mx-auto' />
                        <p className="para text-center pt-4 pb-16">
                        Notre bureau d’étude vous apporte un support qualitatif dans les projets initiés.
                        </p>

                        <a href="https://ged-consulting.group-ged.com" rel="noreferrer" target="_blank"
                           className='bg-[#5f9fad] text-white p-2 rounded-2xl mx-[10%] w-full'>
                              Découvrir GED Consulting
                        </a>
                     </div>
                  </details>
               </div>

            </div>
         </div>
      </section>
   )
}

export default Entreprises;
