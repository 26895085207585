
import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import ScrolltoTop from './ScrolltoTop';


const Footer = () => {
  return (
    <Fragment>
        <footer className='bg-gray-900 text-gray-400 py-10'>
          <div className="banner grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <img src="./images/logoBlanc.png" alt="Logo du group-GED, variant blanc" className='w-34 h-32 my-auto p-2' />
              </div>

              <div>
                <h4 className='footer-title'>Nos coordonnées</h4>

                <div className='py-3 text-sm'>
                    <p>
                      <i className='bx bxs-phone-call'></i> : (00241) 11 537 190
                    </p>

                    <p>
                      <i className='bx bx-mobile'></i> : (00241) 65 557 495 / (00241) 77 331 946
                    </p>

                    <p>
                      <i className='bx bxs-map'></i> BP: 2562 Rue du Dr BALLAY (Face à l'ancien Electra)
                    </p>

                    <p>
                      <i className='bx bxs-envelope'></i> : contact@group-ged.com
                    </p>
                </div>
              </div>

              <div className='px-4'>
                <h4 className='footer-title'>Retrouvez-nous sur :</h4>
                <div className="socials divider-x">
                    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                      <i className='bx bxl-facebook-circle'></i>
                    </a>
                    <a href="https://twitter.com/?lang=fr" target="_blank" rel="noreferrer">
                      <i className='bx bxl-twitter'></i>
                    </a>
                    <a href="https://fr.linkedin.com/" target="_blank" rel="noreferrer">
                      <i className='bx bxl-linkedin'></i>
                    </a>

                    <a href="https://api.whatsapp.com/send/?phone=24177331946" target="_blank" rel="noreferrer">
                      <i className='bx bxl-whatsapp'></i>
                    </a>
                </div>
              </div>
          </div>
          <hr className='opacity-20'/>
          <div className="banner grid grid-cols-1 md:grid-cols-2 gap-2 py-4">
              <div>
                  <p>
                    &copy; 2022, tous drois réservés. Designed by Group - GED
                  </p>
              </div>

              <div>
                  <p className='md:text-right'>
                    <Link to="/mentions-legales">
                      <i class='bx bxs-bookmark'></i> Mentions légales
                    </Link>
                  </p>
              </div>
          </div>
          <ScrolltoTop />
        </footer>
        
    </Fragment>
  )
}

export default Footer
