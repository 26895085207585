import React from 'react';
import { Typewriter } from 'react-simple-typewriter';

function Accueil() {
   return (
      <section>
         <div className="banner grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-1">
            <div className='my-auto'>
               <h1 className='title pt-24 text-center md:text-left'>
                  <span className='bleu'>Group -  </span><span className='rouge'>GED</span> <br />
                  <span className='substitle'>
                     <Typewriter 
                        loop={1}
                        cursor
                        cursorStyle=''
                        typeSpeed={40}
                        deleteSpeed={80}
                        delaySpeed={5000}
                        words={["Le Réseau Des Services Personnalisés"]}
                     />
                  </span>
               </h1>
               <h6 className='para text-center md:text-left'>
               <Typewriter 
                        loop={1}
                        cursor
                        cursorStyle=''
                        typeSpeed={40}
                        deleteSpeed={80}
                        delaySpeed={5000}
                        words={["We are going somewhere !"]}
                     />
               </h6>
            </div>
            <div>
               <img src="./images/heroAccueil.svg" alt="Présentation du Group-GED"  className='w-full px-3 md:px-0 md:h-screen' />
            </div>
         </div>
         
         {/* <span className='text-6xl px-36 py-12 text-[#b5252d] text-center'>
                  <i className='bx bxs-chevron-down animate-bounce'></i>
         </span> */}
      </section>
   )
}

export default Accueil;
