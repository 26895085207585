import React from 'react';
import {Link} from 'react-router-dom';

function HomeHelp() {
   return (
      <section className='bg-[#22458d] py-14 shadow-xl shadow-[#22458d]/40'>
         <div className="banner">
            <h3 className='substitle-blanc pb-1'>Comment pouvons-nous vous aider ?</h3>
            <div className="trait-blanc"></div>
            <p className="para-blanc py-3">
               Vous avez un projet qui vous tient à cœur? Vous souhaitez le concrétiser dès aujourd’hui,
               nous sommes à votre écoute.
            </p>
            <div className='py-5'>
               <Link to="/contact" className="btn-blanc float-right">Contactez-nous</Link>
            </div>
         </div>
      </section>
   )
}

export default HomeHelp;
