import {useState, useEffect} from 'react';

function ScrolltoTop(showBelow) {
   const [show, setShow] = useState(showBelow ? false : true);

   const handleScroll = () => {
      if (window.pageYOffset > showBelow) {
         if(!show) setShow(true);
      } else{
         if(show) setShow(false);
      }
   };

   useEffect(() => { 
      if(showBelow) {
         window.addEventListener(`scroll`, handleScroll);
         return () => window.removeEventListener(`scroll`, handleScroll)
      }
   });

   const handleClick = () => {
      window[`scrollTo`]({ top: 0, behavior: `smooth`});
   };
   return (
      <div>
         <button showBelow={100} className='fixed bottom-0 right-5 z-50' onClick={handleClick}>
            <i className="bx bx-chevron-up w-10 h-10 bg-[#b5252d] bx-md py-[10%] text-gray-200 rounded-full animate-bounce"></i>
         </button>
      </div>
   )
}

export default ScrolltoTop;
