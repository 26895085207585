import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

function MentionsLegales() {
   return (
         <>
            <Navbar />
            <main>
               <section className='py-16'>
                  <div className="banner">
                     <h1 className='title'>Mentions Légales</h1>
                  </div>
               </section>
            </main>
            <Footer />
         </>
   )
}

export default MentionsLegales;
