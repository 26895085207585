import React from 'react';

function Aboutvision() {
   return (
      <section className='py-12 bg-[#22458d]'>
         <div className="banner">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
               <div>
                  <h3 className="substitle-blanc pb-1">Notre vision</h3>
                  <div className="trait-blanc"></div>
                  <div className="px-4">
                     <p className='py-4 para-blanc'>
                        Nous exerçons dans un environnement et un système de 
                        pour créer de la valeur ajoutée dans le domaine de la
                        plus en plus évolutif, notre vision est d’intégrer la diversité logistique terrestre et la gestion 
                        documentaire et archivistique.
                     </p>
                  </div>
               </div>

               <div>
                  <h3 className="substitle-blanc pb-1">Notre philosophie</h3>
                  <div className="trait-blanc"></div>
                  <div className="px-4">
                     <p className='py-4 para-blanc'>
                        Le GROUP-GED réalise des prestations de qualité dans une recherche constante de 
                        la satisfaction du client, nous proposons des issues efficaces et fiables à la 
                        demande des entreprises.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default Aboutvision;
