import React, { Fragment} from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Abouthero from '../components/about/Abouthero';
import SectionAbout from '../components/about/SectionAbout';
import Aboutgroupged from '../components/about/Aboutgroupged';
import Aboutvision from '../components/about/Aboutvision';
import Aboutvalues from '../components/about/Aboutvalues';


const About = () => {
   return (
      <Fragment>
         <Navbar />

         <main>
            <Abouthero />
            <SectionAbout />
            <Aboutgroupged />
            <Aboutvision />
            <Aboutvalues />
         </main>

         <Footer />
      </Fragment>
   )
}

export default About;