import React from 'react';

const SectionAbout = () => {
   return (
         <section className='py-12 bg-[#22458d]'>
            <div className="banner">
               <h3 className="substitle-blanc pb-1">Notre histoire</h3>
               <div className="trait-blanc"></div>
               <div className="columns-1 md:columns-2 py-2 para-blanc md:gap-10 text-sm">
                  <p>
                     L’idée du groupe, née avec la création de deux premières filiales dont SGGDDI en 2011 et 
                     GED Afric en 2015.C’est en 2018 que GED Services voit le jour et en 2020 pourla dernière 
                     filiale GED Consulting. C’est au cours de cette même année que le groupe prend véritablement 
                     forme.
                  </p>
                  <br />
                  <p>
                     Le groupe a été créé pour apporter des solutions innovantes aux dirigeants et un 
                     accompagnement pour les entreprises.
                  </p>
                  <br />
                  <p>
                     Depuis sa création, le Groupe GED accompagne ses clientsdans leurs projets d’organisation, 
                     de construction et de transformation de leurs Systèmes d’Information et de gestionen leur 
                     apportant son expertise. De L’archivage (Organisationélectronique, Processus, Recherche de 
                     Solution, et Gestion) à la mise en œuvre. Nos missions sont multiples et aident nos clients 
                     à optimiser leur performance. <br />
                     Notre modèle s’appuie sur quatre(4) grands axes :
                     <ul className='py-3 pl-6'>
                        <li><i className='bx bxs-right-arrow'></i> La recherche permanente de la performance (efficacité)</li>
                        <li><i className='bx bxs-right-arrow'></i> Une qualité exemplaire</li>
                        <li><i className='bx bxs-right-arrow'></i> Une proximité</li>
                        <li><i className='bx bxs-right-arrow'></i> Une fiabilité</li>
                     </ul>
                  </p>
               
               <p>
                  Pour vous accompagner au plus près de vos besoins et vous apporter le plus haut niveau de 
                  réactivité, nos quatre(4) filiales sont implantées au cœur de votre zone d’activité. Nous sommes engagés 
                  à vos côtés pour la réussite de vos projets.
               </p>
               <br />
               <p>
                  En tant qu’acteur engagé et innovant, notre modèle d’organisation et de gestion est basé sur la 
                  responsabilité et la capacité d’entreprendre la coordination des systèmes de gestions et de 
                  services de proximité efficaces. Cela confère fiabilité, proximité, réactivité et efficacité à 
                  nos clients. Nos salariés sont au cœur de notre stratégie, nous nous sommes engagés à mettre en 
                  place un cadre épanouissant pour le développement de leurs compétences et carrières. Nous 
                  consacrons une énorme importance à la motivation de nos membres et à leur implication. Ses valeurs 
                  se sont ancrées dans l’ADN du GROUP-GED et sont aujourd'hui partagées par l’ensemble de l’équipe.
               </p>
               </div>
            </div>
         </section>
   )
}

export default SectionAbout;
