import React from 'react';
import Values from './Values';

function Aboutvalues() {
   return (
      <section className='py-12'>
         <div className="banner">
            <h3 className='substitle pb-1'>Nos valeurs </h3>
            <div className="trait"></div>
            <p className='px-4 py-5 para'>
               Nos valeurs sont basées sur des principes fondamentaux qui nous sont propres. Elles régissent notre 
               approche de travail et les conditions de collaboration avec nos clients.
            </p>
            <Values />
         </div>
      </section>
   )
}

export default Aboutvalues
