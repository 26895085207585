import React from 'react';

function Nothings() {
   return (
      <section className='bg-gray-100 py-12'>
            <div className="banner">
               <h3 className='substitle pb-1'>Nos derniers articles</h3>
               <div className="trait"></div>
               <p className='py-5 para'>
                  Nous n'avons aucun post de rédigé pour le moment!
               </p>
            </div>
         </section>
   )
}

export default Nothings;