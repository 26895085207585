import React, { Fragment } from 'react';
import Accueil from '../components/home/Accueil';
import Footer from '../components/Footer';
import HomeHelp from '../components/home/HomeHelp';
import Navbar from '../components/Navbar';
import Parteners from '../components/home/Parteners';
import SectionHome from '../components/home/SectionHome';

const Home = () => {
  return (
    <Fragment>
      
      <Navbar />

      <main>

        <Accueil />
        <SectionHome />
        <HomeHelp />
        <Parteners />
      </main>

      <Footer />

    </Fragment>
  )
}

export default Home;
