import React from 'react';
import {Link} from 'react-router-dom';

function SectionHome() {
   return (
      <section className='py-12 bg-gray-100'>
         <div className="banner">
            <h3 className='substitle pb-1'>Qui sommes-nous ?</h3>
            <div className="trait"></div>
            <div className='py-10'>
               <p className='pb-5 para'>
                  Le GROUP-GED est une entreprise multi-services, présente aussi bien dans les services de
                  proximité à travers la location et la logistique, que dans les prestations numériques diverses
                  comme la réalisation d’un système de gestion d’organisation documentaire, d’archivage
                  physique et d'archivage électronique pour les entreprises...
               </p>
               <Link to="/a-propos" className='btn'>A propos de nous</Link>
            </div>
            <p className="para pb-6">
               Découvrez l’ensemble de nos activités
            </p>
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 md:grid-cols-4 p-4 md:gap-5">

               <div className='cursor-pointer max-w-sm mx-auto'
               data-aos="zoom-in" data-aos-easing="linear" data-aos-delay="600" data-aos-duration="1000"
               >
                  {/* <img src="./images/Archivage.png" alt="" className='mx-auto' /> */}
                  <div className='bg-[#FA5C52] rounded-t-2xl p-4'>
                     <h5 className='pb-4 text-xl text-white uppercase text-center font-bold'>Archivage</h5>
                  </div>

                  <div className='bg-gray-100 rounded-b-2xl h-52 p-5 text-[#FA5C52] mx-auto shadow-xl 
                  shadow-[#FA5C52]/50'>
                     <div className="my-[15%]">
                        <span className='font-semibold'>Archivage physique & <br /> Archivage électronique</span>
                     </div>
                  </div>
               </div>

               <div className='cursor-pointer max-w-sm mx-auto'
               data-aos="zoom-in" data-aos-easing="linear" data-aos-delay="700" data-aos-duration="1000"
               >
                  {/* <img src="./images/GED.png" alt="" className='mx-auto' /> */}
                  <div className='bg-[#9FB366] rounded-t-2xl p-4'>
                     <h5 className='pb-4 text-xl text-white uppercase text-center font-bold'>Gestion documents</h5>
                  </div>

                  <div className='bg-gray-100 h-52 p-5 text-[#9fb366] rounded-b-2xl mx-auto shadow-xl 
                  shadow-[#9FB366]/50'>
                     <div className="my-[15%]">
                        <span className='font-semibold'>Gestion électronique des <br /> documents</span>
                     </div>
                  </div>
               </div>

               <div className="cursor-pointer max-w-sm mx-auto"
                  data-aos="zoom-in" data-aos-easing="linear" data-aos-delay="800" data-aos-duration="1000"
               >
                  {/* <img src="./images/Location.png" alt="" className='mx-auto' /> */}
                  <div className='bg-[#FDB053] rounded-t-2xl p-4'>
                     <h5 className='pb-4 text-xl text-white uppercase text-center font-bold'>Location-Logistique</h5>
                  </div>

                  <div className='bg-gray-100 h-52 p-5 text-[#fdb053] rounded-b-2xl mx-auto shadow-xl 
                  shadow-[#FDB053]/50'>
                     <div className="my-[15%]">
                        <span className='font-semibold'>Location de véhicules <br /> Logistique</span>
                     </div>
                  </div>
               </div>

               <div className="cursor-pointer max-w-sm mx-auto"
                  data-aos="zoom-in" data-aos-easing="linear" data-aos-delay="900" data-aos-duration="1000">
                  {/* <img src="./images/Doc-control.png" alt="" className='mx-auto' /> */}
                  <div className='bg-[#5f9fad] rounded-t-2xl p-4'>
                     <h5 className='pb-4 text-xl text-white uppercase text-center font-bold'>Document Control</h5>
                  </div>

                  <div className='bg-gray-100 h-52 p-5 text-[#5f9fad] rounded-b-2xl mx-auto shadow-xl 
                  shadow-[#5F9FAD]/50'>
                     <div className="my-[15%]">
                        <span className='font-semibold'>Support technique projet <br /> Document control</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default SectionHome;
