import {Routes, Route} from 'react-router-dom';
import About from './pages/About';
import Filiales from './pages/Filiales';
import Home from './pages/Home';
import Blog from './pages/Blog';
import './App.css';
import MentionsLegales from './pages/MentionsLegales';
import Nofound from './pages/Nofound';

function App() {
  return (
    <Routes>
      <Route path="/" element={ <Home /> } />
      <Route path="/a-propos" element={ <About /> } />
      <Route path="/blog" element={ <Blog /> } />
      <Route path="/filiales" element={ <Filiales /> } />
      <Route path="/mentions-legales" element={ <MentionsLegales /> } />
      <Route path="/*" element={ <Nofound /> } />
    </Routes>
  );
}

export default App;
