import React, { Fragment} from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Bloghome from '../components/blog/Bloghome';
import Nothings from '../components/blog/Nothings';



const Blog = () => {
   return (
      <Fragment>
         <Navbar />

         <main>
            <Bloghome />
            <Nothings />
         </main>

         <Footer />
      </Fragment>
   )
}

export default Blog;