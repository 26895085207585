import React from 'react';

function Abouthero() {
   return (
      <section className="w-full bg-about-img h-screen bg-cover bg-center bg-no-repeat object-cover">
         <div className="container mx-auto px-7 py-24 md:px-10">
            <div className='py-24 bg-black/40 px-5 rounded-lg text-center'>
               <h1 className='text-white font-ged font-black text-4xl md:text-6xl'>NOUS VOUS PARLONS DE NOUS</h1>
            </div>
         </div>
      </section>
   )
}

export default Abouthero;
