import React from 'react';
import BlogImg from '../../images/heroBlog.svg';

function Bloghome() {
  return (
    <section className='py-12'>
        <div className="banner">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                    {/* IMAGE DE COUVERTURE DE LA PAGE BLOG */}
                    <img src={BlogImg} alt="banière du blog" className='w-full h-[100%]' />
                </div>

                <div className='my-auto px-4'>
                    <h1 className="substitle pb-3">L'ACTUALITE DU GROUP-GED</h1>
                    <p className="para py-2">
                        Ne manquez pas de consulter notre blog pour avoir toutes les dernières informations nous
                        concernant. 
                    </p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Bloghome;