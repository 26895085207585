import React from 'react';

function Values() {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 px-4 py-6">
      {/* BLOC 1 DES VALEURS */}
        <div className='py-6 mx-auto'>
            <i className="bx bx-trophy bx-lg p-2 bg-[#b5252d] text-gray-200 rounded-full"></i> 
            <span className='pl-3 para font-semibold'>Efficacité</span>
        </div>
        <div className='py-6 mx-auto'>
            <i className="bx bx-like bx-lg p-2 bg-[#b5252d] text-gray-200 rounded-full"></i> 
            <span className='pl-3 para font-semibold'>Fiabilité</span>
        </div>
        <div className='py-6 mx-auto'>
            <i className="bx bxs-bolt bx-lg p-2 bg-[#b5252d] text-gray-200 rounded-full"></i> 
            <span className='pl-3 para font-semibold'>Flexibilité</span>
        </div>
        {/* BLOC 2 DES VALEURS */}
        <div className='py-6 mx-auto'>
            <i className="bx bx-shield bx-lg p-2 bg-[#b5252d] text-gray-200 rounded-full"></i> 
            <span className='pl-3 para font-semibold'>Sécurité</span>
        </div>
        <div className='py-6 mx-auto'>
            <i className="bx bx-check-double bx-lg p-2 bg-[#b5252d] text-gray-200 rounded-full"></i> 
            <span className='pl-3 para font-semibold'>Qualité</span>
        </div>
        <div className='py-6 mx-auto'>
            <i className="bx bx-group bx-lg p-2 bg-[#b5252d] text-gray-200 rounded-full"></i> 
            <span className='pl-3 para font-semibold'>Proximité</span>
        </div>
    </div>
  )
}

export default Values;